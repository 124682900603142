body {
  margin: 0;
  /* font-family: "Noto Sans Thai", sans-serif; */
  /* font-family: -apple-system, BlinkMacSystemFont; */
  /*"Segoe UI", "Roboto", "Oxygen",*/
  /*"Ubuntu", "Cantarell", "Fira Sans",*/
  /*"Droid Sans", "Helvetica Neue", sans-serif !important;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
  background-color: rgba(17, 24, 40, 0.06);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
