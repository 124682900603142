@import "react-pro-sidebar/dist/css/styles.css";
@import url("https://fonts.googleapis.com/css2?family=Prompt:wght@100;200;300;400;500;600;700;800;900&display=swap");
* {
  font-family: "Prompt", sans-serif !important;
}
html,
body {
  font-size: 12px;
  background-color: white;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.active {
  color: #66e095f5 !important;
  background-color: rgba(245, 190, 145, 0.09);
  border-right: 3px solid #66e095f5;
  /* border-radius: 8px; */
}

.appBarLink {
  font-size: 8px !important;
  color: #ffff;
  text-decoration: none;
}

.appBarLink:hover {
  color: #66e095f5 !important;
  /* background-color: rgba(245, 191, 147, 0.09); */
  /* border-radius: 8px; */
}

.css-14ogsoa-MuiModal-root-MuiPopover-root-MuiMenu-root {
  color: red !important;
}

.underline-style {
  border-bottom: 1px solid rgba(45, 55, 72, 0.13);
}

.filter {
  margin: 10px;
}

.btnactive {
  color: #f7690afb !important;
  border-bottom: 3px solid #f7690afb;
  /* border-radius: 8px; */
}

.btn {
  font-size: 12px !important;
  color: rgb(37, 35, 35);
}

.btn:hover {
  color: #f7690afb !important;
}

.row:hover {
  color: #f7690afb !important;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: rgb(218, 216, 216);
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

.Report-reason {
  white-space: pre-wrap; /* or pre-line */
}
