#input:enabled {
  background: pink;
}

#inputupdate:enabled {
  background: pink;
}

#inputupdate {
  color: rgba(250, 16, 16, 0.884);
}
